<template>
    <div>
        <div class="justify-space-between">
            <div
                class="flex-1 mr-10"
            >
                <h6 class="text-left color-identify under-line-identify">심사내용</h6>
                <table
                    class="table th-left td-right"
                >
                    <col width="160px"/>
                    <col width="auto"/>
                    <tbody>
                    <tr>
                        <th>신청일</th>
                        <td>{{ item.registerDate }}</td>
                    </tr>
                    <tr>
                        <th>서비스</th>
                        <td>{{ service_name }}</td>
                    </tr>
                    <tr>
                        <th>신청자</th>
                        <td>{{ item.userName }}</td>
                    </tr>
                    <tr>
                        <th>연락처</th>
                        <td>{{ item.userHp }}</td>
                    </tr>
                    <tr>
                        <th>구분</th>
                        <td>{{ type_name }}</td>
                    </tr>
                    <tr>
                        <th>입주건물주소</th>
                        <td>{{ item.address }} {{ item.addressDetail }}</td>
                    </tr>
                    <tr>
                        <th>임대인 은행명</th>
                        <td>{{ item.bankName }}</td>
                    </tr>
                    <tr>
                        <th>임대인 계좌번호</th>
                        <td>{{ item.account }}</td>
                    </tr>
                    <tr>
                        <th>임대인명</th>
                        <td>{{ item.lessor }}</td>
                    </tr>
                    <tr>
                        <th>입금자명</th>
                        <td>{{ item.depositor }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div
                class="flex-1 mr-10"
            >
                <h6 class="text-left color-identify under-line-identify">결제 예정 금액</h6>
                <table
                    class="table th-left td-right"
                >
                    <col width="160px"/>
                    <col width="auto"/>
                    <tbody>
                    <tr v-if="payment_type_name">
                        <th>납입타입</th>
                        <td>{{ payment_type_name }} <span
                            v-if="item.paymentType != codes.montyl_payment_n">({{ item.paymentTotalCount }}회)</span>
                        </td>
                    </tr>
                    <tr v-if="item.cardName">
                        <th>신용카드</th>
                        <td>{{ item.cardName }} {{ item.cardNumber }}</td>
                    </tr>
                    <tr v-if="item.state != 0 && item.state != 2 && item.state != 6">
                        <th>할부</th>
                        <td>
                            <span v-if="item.installment == 0">일시불</span>
                            <span v-else>{{ item.installment }}개월</span>
                        </td>
                    </tr>
                    <tr v-if="item.paymentDate">
                        <th>납입 예정일</th>
                        <td>{{ item.paymentDate }}</td>
                    </tr>
                    <tr v-if="payment_period">
                        <th>납입기간</th>
                        <td>{{ payment_period }}</td>
                    </tr>
                    <tr>
                        <th>납입금액</th>
                        <td class="flex-row" v-if="item.state == 0">
                            <input type="number"
                                   class="pa-5-10 box vertical-middle mr-5 text-right width-75"
                                   v-model="item.amount"
                            >
                            <button class="pa-5 bg-identify" style="min-width: 40px;" @click="modifyAmount">확인</button>
                        </td>
                        <td v-else>{{ item.amount | makeComma }}원</td>
                    </tr>
                    <tr>
                        <th>수수료({{ item.feeRate }}%)</th>
                        <td>{{ item.fee | makeComma }}원</td>
                    </tr>
                    <tr>
                        <th>총결제금액</th>
                        <td>{{ item.totalPaymentAmount | makeComma }}원</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mt-10">
            <table
                class="table"
            >
                <col width="160px"/>
                <col width="auto"/>
                <tbody>
                <tr>
                    <th>첨부서류</th>
                    <td class="text-left">
                        <button
                            v-for="(file, index) in item.fileList"
                            :key="'file_' + index"

                            class="pa-5-10 mr-10 box"

                            @click="toFile(file)"
                        >
                            <v-icon small>mdi mdi-image</v-icon>
                            {{ file.originFileName }}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

            <div
                v-if="item.state == 0 || item.state == 2"
            >
					<textarea
                        class="mt-10"
                        v-model="item.reason"
                        placeholder="심사 반려시 사유를 반드시 입력하세요"
                        :readonly="item.state == 2"
                        :class="{ 'bg-eee': item.state == 2} "
                    ></textarea>

                <div class="mt-10 text-left"><label><input type="checkbox" v-model="item.agree" value="1"/> 심사 시 위 내용을 꼭
                    확인하세요</label></div>
            </div>
            <div
                slot="modal-bottom"
                class="pa-10 justify-center"
            >
                <button
                    v-if="item.state == '0'"
                    class="pa-10 bg-identify-outline mr-10"
                    @click="$emit('doUpdate', item, '2')"
                >심사반려
                </button>
                <button
                    v-if="item.state == 0 || item.state == 2"
                    class="pa-10 bg-green mr-10"
                    @click="$emit('doUpdate', item, '6')"
                >심사완료
                </button>
                <button
                    class="pa-10 bg-identify"
                    @click="$emit('close')"
                >확인
                </button>
            </div>
        </div>

        <Modal
            :is_modal="is_modal"
            :top="true"
            :option="{}"

            width="450px"
            title="첨부파일"

            @close="is_modal = !is_modal"
        >
            <div slot="modal-content"><img :src="file.path" class="width-100"/></div>

        </Modal>
    </div>
</template>

<script>
import Modal from "@/components/Modal";

export default {
    name: 'MonthlyReviewDetail'
    , components: {Modal}
    , props: ['idx', 'service']
    , data: function () {
        return {
            program: {
                name: '월세 심사 상세'
                , title: false
                , top: false
                , bottom: false
            }
            , item: {}
            , is_modal: false
            , file: ''
        }
    }
    , computed: {
        type_name: function () {
            let txt = ''
            for (let i = 0; i < this.codes.monthly_type.length; i++) {
                if (this.item.type === this.codes.monthly_type[i].code) {
                    txt = this.codes.monthly_type[i].name
                    break
                }
            }
            return txt
        }
        , service_name: function () {
            let txt = ''
            for (let i = 0; i < this.codes.monthly_service.length; i++) {
                if (this.item.service == this.codes.monthly_service[i].code) {
                    txt = this.codes.monthly_service[i].name
                    break
                }
            }
            return txt
        }
        , payment_type_name: function () {
            let txt = ''
            for (let i = 0; i < this.codes.monthly_payment.length; i++) {
                if (this.item.paymentType == this.codes.monthly_payment[i].code) {
                    txt = this.codes.monthly_payment[i].name
                    break
                }
            }
            return txt
        }
        , payment_period: function () {
            let txt = ''
            if (this.item.paymentStartDate || this.item.paymentEndDate) {
                if (this.item.paymentType == this.codes.montyl_payment_n) {
                    txt = '-'
                } else {
                    txt = this.item.paymentStartDate + ' ~ ' + this.item.paymentEndDate
                }
            } else {
                txt = null
            }

            return txt
        }
    }
    , methods: {
        getData: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'monthly/audit/' + this.idx
                    , data: {
                        service: this.service
                    }
                })

                if (result.success) {
                    console.log(result.data)
                    this.item = result.data
                    this.$set(this.item, 'is_agree', false)
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
            } finally {
                this.$layout.offLoading()
            }
        }
        , toFile: function (file) {
            this.is_modal = true
            this.file = file
        }

        , modifyAmount: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'put'
                    , url: 'monthly/amount/'
                    , data: {
                        service: this.service
                        , idx: this.idx
                        , amount: this.item.amount
                    }
                })

                if (result.success) {
                    this.$layout.setNotify( {type: 'success', message: result.message})
                    await this.getData()
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
                this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
            } finally {
                this.$layout.offLoading()
            }
        }

    }
    , created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.getData()
    }
}
</script>